import { useState } from 'react';
import Query from './components/Query';
import bg from './images/bg-01.jpg'
import logo from './images/logo.png'
import axios from 'axios';

function App() {


  const [inGeneration, setIngeneration] = useState(false)
  const [generated, setGenerated] = useState(null)

  const searchHandler = async (event) => {
    setGenerated(null)
    setIngeneration(true)
    try{
      const body = {
        prompt: event,
        n: 1,
        size: "1024x1024"
      }
      const headers = {
        'content-type': 'application/json',
        'X-RapidAPI-Key': '046f26676dmsha4c8f5c5e1b014fp128393jsn59a57d5ca2c0',
        'X-RapidAPI-Host': 'openai80.p.rapidapi.com'
      }
      const response = await axios.post('https://openai80.p.rapidapi.com/images/generations', body, {headers})
      setIngeneration(false)
      setGenerated(response.data.data[0].url)
      // console.log(response.data)
    }catch(e){
      setIngeneration(true)
    }
    setIngeneration(false)
  }

  return (
    <div className="h-screen bg-no-repeat bg-cover bg-center" style={{backgroundImage: `url(${bg})`}}>
      <div className='h-full bg-black bg-opacity-70 pt-10'>
      <img src={logo} className='h-12 mx-auto mb-10' alt='logo' />
          <div className='w-96 mx-auto mb-24'>
              <Query onSearch={searchHandler} />
          </div>

          <div className={`w-96 h-96 mx-auto bg-white rounded-lg flex items-center justify-center ${!inGeneration ? 'hidden' : 'block'}`}>
              <span>Please wait ...</span>
          </div>
          <div className={`w-96 h-96 mx-auto rounded-lg overflow-hidden ${!generated ? 'hidden' : 'block'}`}>
              <img src={generated} className='w-full h-full' alt='' />
          </div>
      </div>
    </div>
  );
}

export default App;
