const Query = ({ onSearch }) => {

    const enterHandler = async (event) => {
        if(event.code === "Enter"){
            await onSearch(event.target.value)
        }
    }

    return (
        <input
            className="rounded-lg w-full px-4 py-2 text-lg focus:outline-none resize-none"
            placeholder="A cat in space ..."
            onKeyUp={enterHandler}
        />
    );
};

export default Query;
